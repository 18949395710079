<template lang="pug">
.fixed-block
  OmInput#url(:label="$t('coupon')" small v-model="fixedCoupon")
  .coupon-data.mb-1(v-if="showFixedCouponSettings")
    .flex-column
      .shopify-coupon-info-box(v-if="showFixedCouponInfo" :class="[shopifyCouponInfo.type]")
        UilCheckCircle(v-if="shopifyCouponInfo.type === 'success'")
        UilExclamationTriangle(v-if="shopifyCouponInfo.type === 'warning'")
        UilExclamationTriangle(v-if="shopifyCouponInfo.type === 'error'")
        .coupon-info-text.mt-1(v-html="shopifyCouponInfo.text")
      OmButton.create-coupon-button.mt-3(
        primary
        small
        block
        @click="createCoupon"
        v-if="showCreateCouponButton"
      )
        span {{ $t('discountPane.fixed.createDiscountCode') }}
      .shopify-coupon-details.mt-4(v-if="showCouponDetails")
        .shopify-coupon-title {{ shopifyFixedCoupon.couponData.code }}
        ul.shopify-coupon-details-list.my-0.px-5
          li.shopify-coupon-details-list-item(v-for="detail in shopifyFixCouponDetails") {{ detail }}
  .loading-coupon(v-if="loadingFixedCoupon && coupon.fixedCoupon && coupon.type === 'fixed'")
    img(:src="require('@/assets/editor/svg/loader.svg')")
</template>
<script>
  import { mapMutations, mapState } from 'vuex';
  import moment from 'moment';
  import itemMixin from '@/editor/mixins/item';
  import sharedMixin from '@/components/Editor/Blocks/Coupon/shared';
  import LOOKUP_COUPON from '@/graphql/LookupCoupon.gql';

  export default {
    components: {
      UilCheckCircle: () => import('@iconscout/vue-unicons'),
      UilInfoCircle: () => import('@iconscout/vue-unicons'),
      UilExclamationTriangle: () => import('@iconscout/vue-unicons'),
    },
    mixins: [itemMixin, sharedMixin],
    data() {
      return {
        moment,
        shopifyFixedCoupon: null,
        typingTimeout: null,
        lookupError: false,
        loadingFixedCoupon: false,
        isTyping: false,
        fixedCouponFeatures: [
          {
            key: 'VALID_FOR_SPECIFIC_PRODUCTS',
            text: this.$t('discountPane.fixed.specificProducts'),
          },
          {
            key: 'VALID_FOR_SPECIFIC_GROUP_OF_CUSTOMERS',
            text: this.$t('discountPane.fixed.specificCustomers'),
          },
          {
            key: 'VALID_FOR_ONCE_PER_CUSTOMER',
            text: this.$t('discountPane.fixed.appliesOncePerCustomer'),
          },
        ],
      };
    },
    computed: {
      ...mapState(['campaign', 'validationError']),
      fixedCoupon: {
        get() {
          return this.getValueOf('data.coupon.fixedCoupon');
        },
        set(v) {
          this.setValueOf('data.coupon.fixedCoupon', v);
        },
      },
      shopifyFixCouponDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return null;
        }

        return [
          ...this.getTypeDetails(),
          ...this.getValidityDetails(),
          ...this.getUsageDetails(),
          ...this.getFixedCouponFeatures(),
          ...this.getMinimumPurchase(),
        ];
      },
      showFixedCouponSettings() {
        return this.showFixedCouponInfo || this.showCreateCouponButton || this.showCouponDetails;
      },
      showCouponDetails() {
        return this.showFixedCouponInfo && this.shopifyFixedCoupon?.couponData;
      },
      showCreateCouponButton() {
        return (
          this.shopifyFixedCoupon?.couponData === null &&
          this.coupon.fixedCoupon &&
          !this.loadingFixedCoupon &&
          !this.lookupError
        );
      },
      showFixedCouponInfo() {
        return !this.loadingFixedCoupon && this.shopifyCouponInfo && this.coupon.fixedCoupon;
      },
      shopifyCouponInfo() {
        if (this.lookupError) {
          return this.fixedCouponCases.error;
        }

        if (this.shopifyFixedCoupon?.couponData === null) {
          return this.fixedCouponCases.notExists;
        }

        if (this.shopifyFixedCoupon?.userCreated) {
          return this.fixedCouponCases.created;
        }

        if (this.shopifyFixedCoupon?.couponData.status === 'EXPIRED') {
          return this.fixedCouponCases.expired;
        }

        if (
          this.shopifyFixedCoupon?.couponData &&
          this.shopifyFixedCoupon?.couponData.usageCount ===
            this.shopifyFixedCoupon?.couponData.usageLimit
        ) {
          return this.fixedCouponCases?.used;
        }

        if (this.shopifyFixedCoupon?.couponData) {
          return this.fixedCouponCases.exists;
        }

        return null;
      },
      shopifyAdminLink() {
        return `http://${this.campaign.domain}/admin/discounts`;
      },
      fixedCouponCases() {
        return {
          notExists: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponDoesNotExist'),
          },
          expired: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponExpired', { link: this.shopifyAdminLink }),
          },
          used: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponUsed', { link: this.shopifyAdminLink }),
          },
          exists: {
            type: 'success',
            text: this.$t('discountPane.fixed.couponExists', { link: this.shopifyAdminLink }),
          },
          created: {
            type: 'success',
            text: this.$t('discountPane.fixed.couponCreated', { link: this.shopifyAdminLink }),
          },
          error: {
            type: 'error',
            text: this.$t('discountPane.fixed.couponLookupError', { link: this.shopifyAdminLink }),
          },
        };
      },
    },
    watch: {
      'coupon.fixedCoupon': function () {
        if (this.isActiveShopify) {
          this.isTyping = true;
          if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
          }
          this.typingTimeout = setTimeout(() => {
            this.isTyping = false;
          }, 1000);
          if (this.validationError?.property === 'data.coupon.fixedCoupon') {
            this.updateValidationErrorProperty(null);
          }
          if (!this.coupon.fixedCoupon) {
            this.shopifyFixedCoupon = null;
          }
        }
      },
      isTyping(value) {
        if (!value && this.coupon.fixedCoupon?.trim()) {
          this.lookupCoupon(this.coupon.fixedCoupon);
        }
      },
    },
    created() {
      this.$bus.$on('couponCreated', this.setCreatedCoupon);
      document.addEventListener('visibilitychange', this.lookupSavedCoupon);
    },
    beforeDestroy() {
      document.removeEventListener('visibilitychange', this.lookupSavedCoupon);
      this.$bus.$off('couponCreated', this.setCreatedCoupon);
    },
    mounted() {
      if (this.isActiveShopify) {
        this.lookupCoupon(this.coupon.fixedCoupon);
      }
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility', 'updateValidationErrorProperty']),
      lookupSavedCoupon() {
        if (
          this.coupon.fixedCoupon &&
          !document.hidden &&
          (this.shopifyFixedCoupon?.couponData === null ||
            this.shopifyFixedCoupon?.couponData.status === 'EXPIRED' ||
            (this.shopifyFixedCoupon?.couponData &&
              this.shopifyFixedCoupon?.couponData.usageCount ===
                this.shopifyFixedCoupon?.couponData.usageLimit))
        ) {
          this.lookupCoupon(this.coupon.fixedCoupon);
        }
      },
      setCreatedCoupon({ couponData }) {
        this.shopifyFixedCoupon = { couponData, userCreated: true };
      },
      lookupCoupon(code) {
        this.loadingFixedCoupon = true;
        this.$emit('loadingFixedCoupon', true);
        this.lookupError = false;
        this.$apollo
          .query({
            query: LOOKUP_COUPON,
            variables: {
              code,
              shopId: this.campaign.domain,
            },
          })
          .then(({ data: { couponData }, errors }) => {
            this.loadingFixedCoupon = false;
            this.$emit('loadingFixedCoupon', false);
            this.lookupError = false;
            this.shopifyFixedCoupon = { couponData };
            this.$emit('shopifyFixedCoupon', !!this.shopifyFixedCoupon);

            if (errors?.length) {
              this.lookupError = true;
            }
          })
          .catch(() => {
            this.lookupError = true;
            this.loadingFixedCoupon = false;
            this.$emit('loadingFixedCoupon', false);
          });
      },
      processBasicDiscountDetails() {
        const { basicRules } = this.shopifyFixedCoupon.couponData;
        const value =
          basicRules.type === 'DiscountPercentage'
            ? `${(basicRules.value * 100).toFixed(0)}%`
            : `${basicRules.value} ${basicRules.currencyCode}`;

        return [this.$t('discountPane.fixed.discount', { value })];
      },
      processShippingDiscountDetails() {
        const {
          shippingDiscountRules: { maximumShippingPrice, specificCountries },
        } = this.shopifyFixedCoupon.couponData;

        return [
          this.$t('discountPane.fixed.freeShipping'),
          ...(specificCountries
            ? [this.$t('discountPane.fixed.specificCountries')]
            : [this.$t('discountPane.fixed.allCountries')]),
          ...(maximumShippingPrice
            ? [
                this.$t('discountPane.fixed.maximumShippingPrice', {
                  shippingMax: `${maximumShippingPrice.amount} ${maximumShippingPrice.currencyCode}`,
                }),
              ]
            : []),
        ];
      },
      processBxGyDiscountDetails() {
        const {
          buyXgetYRules: { type, amount, buyQuantity, discountPercentage, getQuantity },
        } = this.shopifyFixedCoupon.couponData;

        if (type === 'DiscountQuantity') {
          if (discountPercentage === 1) {
            return [
              this.$t('discountPane.fixed.buyXgetY', {
                x: buyQuantity,
                y: getQuantity,
              }),
            ];
          }
          return [
            this.$t('discountPane.fixed.buyXgetYDiscounted', {
              x: buyQuantity,
              y: getQuantity,
              discount: `${discountPercentage * 100}%`,
            }),
          ];
        }
        if (type === 'DiscountPurchaseAmount') {
          if (discountPercentage === 1) {
            return [
              this.$t('discountPane.fixed.buyAmountgetY', {
                x: amount,
                y: getQuantity,
              }),
            ];
          }
          return [
            this.$t('discountPane.fixed.buyAmountgetYDiscounted', {
              x: amount,
              y: getQuantity,
              discount: `${discountPercentage * 100}%`,
            }),
          ];
        }
      },
      getTypeDetails() {
        const types = {
          DiscountCodeBasic: this.processBasicDiscountDetails,
          DiscountCodeFreeShipping: this.processShippingDiscountDetails,
          DiscountCodeBxgy: this.processBxGyDiscountDetails,
        };
        const { type } = this.shopifyFixedCoupon.couponData;

        return types[type]();
      },
      getValidityDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return null;
        }
        const { startsAt, endsAt } = this.shopifyFixedCoupon.couponData;

        const dateFormat = this.$i18n.locale === 'hu' ? 'YYYY-MM-DD HH:mm' : 'MM/DD/YYYY hh:mm a';

        return [
          ...(startsAt
            ? [
                this.$t('discountPane.fixed.validFrom', {
                  startsAt: moment(startsAt).format(dateFormat),
                }),
              ]
            : []),
          ...(endsAt
            ? [
                this.$t('discountPane.fixed.validUntil', {
                  endsAt: moment(endsAt).format(dateFormat),
                }),
              ]
            : []),
        ];
      },

      getUsageDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { usageLimit, usageCount } = this.shopifyFixedCoupon.couponData;

        const details = [];

        const usageParams = { usageLimit, usageCount };
        for (const key in usageParams) {
          if (usageParams[key]) {
            details.push(
              this.$t(`discountPane.fixed.${key}`, {
                ...(usageParams[key] && { value: usageParams[key] }),
              }),
            );
          }
        }
        return details;
      },

      getFixedCouponFeatures() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { features } = this.shopifyFixedCoupon.couponData;

        if (!features) return [];

        return features.reduce((featureTexts, feature) => {
          const fixedFeature = this.fixedCouponFeatures.find((f) => f.key === feature);
          if (fixedFeature) {
            featureTexts.push(fixedFeature.text);
          }
          return featureTexts;
        }, []);
      },

      getMinimumPurchase() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { minimumPurchase } = this.shopifyFixedCoupon.couponData;

        if (minimumPurchase) {
          const purchaseValue =
            minimumPurchase.type === 'greaterThanOrEqualToQuantity'
              ? `${minimumPurchase.value} items`
              : `${minimumPurchase.value} ${minimumPurchase.currencyCode}`;
          return [this.$t('discountPane.fixed.minimumPurchase', { purchaseValue })];
        }
        return [];
      },
      createCoupon() {
        this.changeFormManagerVisibility({
          show: 'couponCreate',
          createDiscountProps: {
            currency: this.currency,
            couponCode: this.coupon.fixedCoupon,
            shopifyAdminLink: this.shopifyAdminLink,
          },
        });
      },
    },
  };
</script>
<style lang="sass">
  .fixed-block
    .input-label
      padding-top: unset !important
</style>
